import { ReceiptPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";

export const onlineContent = "online";
export const preferredemail =
  "brand.supermarket.content.all.mode.secondemail.preferredemail";
export const digitalReceipts = "communication.digitalReceipts";
export const instoreContent = "instore";
export const paper = "paper";
export const email = "email";

export const getBrandSpecificDescriptor = (brand?: string) => {
  switch (brand) {
    case "supermarket":
      return "Coles Supermarkets";
    default:
      return "Coles Supermarkets";
  }
};

export const generateModeDescription = (mode: string) => {
  switch (mode) {
    case "email":
      return "Email receipts";
    case "paper":
      return "Paper receipts";
    default:
      return `${mode[0].toUpperCase()}${mode.slice(1)}`;
  }
};

export const mapPreferences = (preferenceCollection: ReceiptPreference[]) => {
  return preferenceCollection.map((i) => {
    return {
      group: i.group,
      name: i.name,
      value: i.value.toString(),
    };
  });
};
